import React from 'react'
import About from './About'
import Socials from './Socials'

function Home() {
  return (
    <div>
        <About />
        <Socials />
    </div>
  )
}

export default Home